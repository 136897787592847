.logo_mc {
    display: inline-block;
    font-family: "Galada";
    color: #fff;
    text-decoration: none;
    //font-size: 2.125em;
    word-spacing: -0.03em;
    position: relative;
    text-align: left;
    line-height: 1;

    &.carre {
        white-space: nowrap;
        line-height: 0.8em;

        &:before {
            top: 0.11em;
            font-size: 2em;
            float: left;
            height: 1em;
        }
    }


    &.meta,
    &.beta {


        span {
            display: block;
            font-family: 'arial';
            position: absolute;

        }
    }

    &.meta {
        &:before {
            top: 0.11em;
        }

        &>a {
            text-decoration: none;
        }

        span {
            color: var(--grey-medium);
            font-size: .45em;
            top: 35px;
            left: 39px;
        }
    }

    &.beta {
        &:before {
            top: 0
        }

        span {
            text-transform: uppercase;
            font-style: italic;
            font-weight: 600;
            color: #f2994a;
            font-size: 0.35em;
            top: 38px;
            left: 46px;
        }
    }

    &:before {
        position: relative;
        font-family: a4i_ico;
        content: "millecheck ";
        font-size: 1.6em;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.11em;
        top: -0.11em;
    }
}

#logo_top {
    position: absolute;
    left: 0.25em;
    top: 0.25em;
    z-index: 2;
    color: #fff;
}

@media (min-width: $mq-2 ) {
    #logo_top {
        left: 0.5em;
        top: 0.5em;
    }
}