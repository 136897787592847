@font-face {
	font-family: 'a4i_ico';
	src:
		url('css/fonts/millecheck_ico.woff2') format('woff2');

	font-weight: normal;
	font-style: normal;
	font-display: swap;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Galada';
	src:
		url('css/fonts/galada.woff2') format('woff2');

	font-weight: normal;
	font-style: normal;
	font-display: swap;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ico {
	font-family: 'a4i_ico', sans-serif;
	font-variant-ligatures: discretionary-ligatures;
	font-feature-settings: "dlig";
	line-height: 1;
	font-size: 1.5em;
	vertical-align: text-bottom;
	font-weight: normal;
	text-transform: none !important;

	&[data-ico] {
		&:before {
			content: attr(data-ico);
		}
	}
}
