@import "variables";
@import "fonts";
@import "normalize";
@import "btn";

@import "footer";
@import "logo_mc";



body {
	color: #fff;
	background: #0f1c2c;
	font-family: Arial, Helvetica, sans-serif;
	overflow-x: hidden;

}

//wrapper
#wrapper {
	padding: 0 1em;
}

header {
	padding: 0 0 0 50px;

	.logo_mc {
		margin: 20px 0 0 -50px;
		font-size: 2em;
		color: #fff;
	}

	.btn {
		float: right;
		margin: 1.5em 0 2em;
	}
}

//main
main {
	max-width: 900px;
	margin: 0 auto;
	padding: 6em 0 4em;
	line-height: 1.4em;

	ul,
	p {
		color: var(--grey-medium);
	}

	strong,
	a {
		color: #fff;
	}

	.img_large {
		width: calc(100% + 2em);
		margin: 1em -1em;
		height: auto;
		box-sizing: border-box;
		max-width: none;
	}

	.prefooter {
		margin: 4em 0 -1em;
	}

	.cta {
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		line-height: 14px;
		padding: 11px 1.5em;
		background: #db525c;
		color: #fff;
		border-radius: 6px;
		margin: 0 auto;
		text-decoration: none;
	}
}

h1 {
	font-family: 'Galada', cursive;
	line-height: 1em;
	font-weight: 400;
	margin: 0;

	.tag {
		display: inline-block;
		background: #e6513a;
		padding: 0.25em 0.5em 0em;
		vertical-align: middle;
		font-size: .75em;

		border-radius: .25em;
		margin: 0 .5em;
	}
}

h1 strong {
	font-weight: 400;
	color: #f2994a;
	position: relative;

	&:after {
		content: "";
		background-image: url("img/etoiles.svg");
		background-size: 1em auto;
		width: 1em;
		height: 0.72em;
		position: relative;
		display: block;
		position: absolute;
		right: -.22em;
		top: -.44em;
	}
}

//fomulaire d'invit
.invitation #consent,
#analyse-launched {
	position: absolute;
	opacity: 0;
	height: 1px;
	width: 1px;
	top: 11em;
	left: 0.5em;
}

.invitation {
	position: relative;

	#consent {
		top: 19em;
	}

	div {
		font-size: 14px;
		line-height: 1.4em;
		margin: 1.5em 0 2em;
		position: relative;
	}

	.part2:before {
		--ld_size: 1em;
		--ld_border: 4px;
		content: " ";
		display: block;
		width: var(--ld_size);
		height: var(--ld_size);
		border-radius: 50%;
		border: var(--ld_border) solid #fff;
		border-color: #fff transparent #fff transparent;
		position: absolute;
		right: 1em;
		top: 5.6em;
		z-index: 5;
		opacity: 0;
	}

	&.submitted {
		button.btn {
			display: none;
		}

		.part2:before {
			animation: loader 1s linear infinite;
			opacity: 1;
		}
	}

	input:valid+.btn {
		background: #5eab59
	}

	input:invalid+.btn {
		pointer-events: none;
	}

	input:invalid:not(:placeholder-shown)+.btn {
		pointer-events: none;
		background: #6a747c;
		color: #fff;
	}

	input:valid+button.btn {
		pointer-events: none;
		background: #6a747c;
		color: #fff;
	}

	.check {
		font-family: Galada;
		font-size: 24px;
		display: inline-block;
		font-weight: 400;
		vertical-align: middle;
		margin: -10px -5px -14px;
		transform: rotate(-5deg);
	}


	#url,
	#address {
		border: 1px solid #1d3243;
		outline: none;

		&:focus {
			border-color: #fff;
		}

		&:valid:not(:placeholder-shown) {
			border-color: #5eab59;
		}

		&:invalid:not(:placeholder-shown) {
			border-color: #f2994a;
		}
	}

	.error_mail,
	.error_url {
		font-size: 12px;
		margin: -5.3em 0 5.3em;
		opacity: 0;
		transition: opacity 0.15s ease-in 0s;
		color: #f2994a;
	}

	.error_mail {
		display: block;
	}



	#url:invalid:not(:placeholder-shown) {
		&~.error_url {
			opacity: 1;
			transition-delay: 3s;
		}
	}

	#address:invalid:not(:placeholder-shown) {
		&~.error_mail {
			opacity: 1;
			transition-delay: 3s;

		}
	}

	button {
		position: relative;
		z-index: 3;
	}

	.part1,
	.part2 {
		min-height: 210px;
	}

	.part2 {
		display: none;
	}

	&:has(.part3) {

		.part1,
		.part2 {
			display: none;
		}
	}

	#analyse-launched:checked {

		&~.part1 {
			display: none;
		}

		&~.part2 {
			display: block;
		}

	}


	.part1 {
		button[type='button'] {
			border: none;
			background: #1d3243;
			border-radius: 3px;
			display: inline-block;
			margin: 0 8px 8px 0;
			line-height: 24px;
			padding: 0 8px;
			color: #aaa;
			cursor: pointer;
			transition: all .25s ease;
			opacity: 0;
		}

		#btnHttps {
			background: #f2994a;
			color: #1d3243;
		}

		button[type='button'].suffix:disabled,
		#btnWww:disabled,
		#btnHttps:disabled {
			background: #5eab59;
			color: #1d3243;
		}
	}

	.part1.enablecontrol {
		button[type='button'] {
			opacity: 1;
		}
	}

	.info {
		font-size: 15px;
		margin-bottom: 1.8em;

		em,
		strong {
			color: #fff;
		}

	}

	label[ for="consent"] {
		display: block;
		clear: both;

		&:before {
			content: "";
			display: inline-block;
			height: 20px;
			width: 20px;
			background: #1d3243;
			border-radius: 4px;
			vertical-align: bottom;
			margin: 0px 5px 2px 0;
			line-height: 22px;
			text-align: center;
			font-size: 22px;
			color: #5eab59;
			border: 1px solid #6a747c;
		}
	}

	#address:valid~p label[for="consent"]:before {
		border-color: #f2994a;
	}

	#consent:checked~div {
		label[ for="consent"] {
			&:before {
				content: "✔";
				border-color: #6a747c !important;
			}
		}

		input:valid+button {
			pointer-events: visible;
			background: #5eab59;
		}


	}
}

.full-width {
	background: rgba(0, 0, 0, 0.25);
	padding: 2em 0;
	max-width: 100%;
	overflow-x: auto;
	font-size: 0.8em;
	--innerpadding: 0.75em
}

.classement-table {

	margin: 0 auto;
	--bg1: #00aec2;
	--bg2: #ff9100;

	.prct_1p {
		color: var(--bg1);
		margin: 0;
		float: left;
		clear: left;
		font-size: 0.9em;
	}

	.prct_3p {
		color: var(--bg2);
		margin: 0;
		float: right;
		clear: right;
		font-size: 0.9em;
	}

	.bar {
		border-radius: 4px;
		display: block;
		vertical-align: middle;
		margin: 0;
		height: 1.64em;
		line-height: 1.64em;
		font-size: 5px;
		color: #000;
		text-indent: 3px;
		box-sizing: border-box;
		white-space: nowrap;

		clear: both;
	}

	.bar {
		--bar_width: 200px;

		--sector1: calc(var(--value1p) / 100 * var(--bar_width));
		--sector2: calc(100 / 100 * var(--bar_width));
		width: var(--bar_width);
		background-image: linear-gradient(90deg, var(--bg1) var(--sector1), var(--bg2) var(--sector1), var(--bg2) 100%);
	}

	td,
	th {
		text-align: left;

		&.text-center {
			text-align: center;
		}
	}

	th {
		font-size: 1.2em;
		padding: 1em var(--innerpadding);

	}

	td {
		font-size: 1.1em;
		padding: 0.5em var(--innerpadding) 0.5em 0;
	}

	td {
		font-weight: bold;
	}

	tr {
		&:hover>td {
			cursor: pointer;
			background: rgba(255, 255, 255, 0.05);
		}

		td,
		th {
			border-left: 1px solid #333;
			padding-left: var(--innerpadding);
			white-space: nowrap;
		}

		&>:first-child {
			border-left: none;

		}

		&>:last-child {
			padding-right: 0;
			width: 200px;
		}
	}



	.position {
		display: inline-block;
		line-height: 2.2em;
		height: 2.2em;
		width: 2.2em;
		border: 1px solid #ddd;
		border-radius: 50%;
		vertical-align: middle;
		background: rgba(255, 255, 255, 0.05);
	}

	.good {
		color: var(--color_good);

	}

	.bof {
		color: var(--color_bof);
	}

	.bad {
		color: var(--color_bad);
	}
}

.invitation,
.contact {

	.btn {
		font-size: 14px;
		line-height: 14px;
		border: 2px solid #1d3243;
		padding: 11px 1.5em;
		float: right;
		margin: -3.08em 2px 0 0;
		background: #db525c;
		color: #fff;

		&[disabled] {
			pointer-events: none;
			opacity: 0.75;
		}
	}



	.input-field {
		position: absolute;
		z-index: -1;
		top: -100000px;
	}

	.error {
		color: var(--color_verybad);
	}

	.success {
		font-size: 1.25em;
		color: var(--color_good);
	}

	.h-captcha {
		margin-top: 2em;
	}


}



@keyframes loader {
	0% {

		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);

	}
}

#url,
#address,
.form-control {
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: block;
	background: #1d3243 !important;
	border-radius: 8px;
	border: none;
	padding: 13px;
	box-sizing: border-box;
	font-family: inherit;
}

//footer
footer {
	align-self: end;
}

.text-center {
	text-align: center;
}



@media (max-width: 1023px) {
	#wrapper.big_shaker {
		main {
			padding: 8px;
			background-image: url(img/mc_txt_digit_trans.webp);
			background-repeat: no-repeat;
			box-sizing: content-box;
			background-size: 400px auto;
			background-position: center bottom;
			min-height: 730px;

			&:has(.part3) {
				min-height: 1090px;
			}
		}

	}

	h1 {
		margin: 1em 0 0.5em;
		font-size: 1.75em;
		clear: both;
	}

	.invitation p br {
		display: none;
	}

	.contact .btn,
	.invitation .btn {
		margin-top: 1em;
	}

	ul {
		line-height: 2em;
	}

}

@media (min-width:1024px) {
	#wrapper {
		padding: 0 2em;
	}

	main {
		.img_large {
			width: calc(100% + 8em);
			margin: 2em -4em;
			border-radius: 26px;
			border: 8px solid #444b53;
			box-sizing: border-box;

		}
	}

	.invitation {

		#url,
		#address {
			height: 46px;
		}

		.btn:not(a) {
			font-size: 14px;
			line-height: 22px;
			padding: 12px 1.5em;
			float: right;
			margin: -46px 0 0 0;
			background: #db525c;
			color: #fff;
			border-radius: 0 6px 6px 0;
			border: none;
		}
	}

	#wrapper.big_shaker {
		display: grid;
		grid-template-rows: 5% auto 5%;
		margin: 0 auto;
		height: 95vh;

		background-size: min(54%, 95vh);
		background-image: url("img/mc_txt_digit_trans.webp");
		background-repeat: no-repeat;
		box-sizing: content-box;
		background-position: 45vw center;

		main {
			background: none;
			max-width: 32em;
			align-self: center;
			padding: 0 0 0 calc(40vw - 28em);
			margin: 0;
		}

		nav {
			padding-bottom: 0;
		}

	}

	h1 {
		font-size: 2.8em;
	}

	.full-width {
		--innerpadding: 2em;
		font-size: 1em;
		max-width: none;

		width: 100vw;
		margin: 4em calc((100vw - 900px) / 2 * -1);
	}

	.classement-table {
		min-width: 1024px;

		th[title]:after {
			content: "i";
			display: inline-block;
			line-height: 19px;
			height: 19px;
			width: 19px;
			vertical-align: top;
			font-size: 14px;
			text-align: center;
			margin-left: 8px;
			border-radius: 50%;
			background: hsla(0, 0%, 100%, .3);
			color: #0a0a0a;
		}
	}

}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}