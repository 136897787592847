.btn {
    border-radius: 8px;
    //border: 1px solid var(--grey-medium);
    border: none;
    background: var(--grey-light);
    color: var(--grey-dark);
    padding: 0.5em 1.5em;
    vertical-align: middle;
    font-size: 1em;
    line-height: 1.8em;
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;


    &.ico {
        line-height: 1;
        padding: 0.5em;
        border-radius: 4px;
        font-weight: 400;
    }

    &.start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 1px;
    }

    &.end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:hover {
        background: var(--grey-medium);
        color: var(--grey-verydark);
        // border-color : var(--grey-dark);
        z-index: 2;
    }

    &:active {
        color: #000;
    }

    img {
        vertical-align: middle;
        margin-top: -0.25em;
    }

    .ico {
        font-size: 1.4em;
    }


    &.smart {
        background: var(--nightblue);
        color: #fff;
    }

    &.negative {
        background: var(--bluemed);

        color: #fff;
        line-height: calc(1.8em - 2px);
        border: 1px solid #fff;
    }

    //correction d'alignement en fonction du picto...
    &.grr {
        .ico {
            vertical-align: inherit;
            margin-left: 0.25em
        }
    }

    &.smart.v2 {
        background: var(--orange);
        color: #fff;
    }

    &:disabled {
        background: var(--grey-medium);
        color: var(--grey-light);
        border: solid 2px var(--grey-light);
        cursor: not-allowed;
    }

    &.inst {
        color: #fff;
        background: #1d3243
    }

    &.foropenclose {
        border-radius: 4px;
        padding: 0.2em .5em;
        line-height: 1.15em;
        vertical-align: baseline;
        font-size: 11px;
    }


    &.foropenclose .ico {
        transform: rotate(-0deg);
        transition: transform .25s ease-out;
        display: inline-block;
    }

    .close &.foropenclose .ico {
        transform: rotate(-180deg);
    }
}