footer {
    font-size: 13px;
    color: var(--grey-medium);

    a {
        color: inherit;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .logo_mc {
        font-size: 2.125em;
    }

    .ico {
        font-size: 1em;
        top: -0.15em;
        position: relative;
        display: inline-block;
        margin-left: 0.5em
    }
}

.bigheader+footer,
#sources+footer {
    background: #0e1e2d;
    color: var(--grey-medium);
    //line-height: 1.4em;
    //font-size: 0.85em;
    margin: 0;
    padding: 3em 0;
}

@media (max-width:1024px) {
    footer li:first-child {

        margin: 0 0 2em;
    }

}

@media (min-width:1024px) {

    footer {
        nav {
            max-width: 1800px;
            text-align: right;
            margin: 0 auto;
            border-top: 1px solid #1d3243;
            padding: 1.5em 0 4em;
        }



        li {
            display: inline-block;
            margin-left: 2em;
        }

        li:first-child {
            float: left;
            margin: 0;
        }

        li+li {
            padding-top: 1.5em;
        }

    }

    .bigheader+footer,
    #sources+footer {
        nav {
            max-width: calc(1326px - 8em);
            border-top: none;
            padding: 0 0 1.5em;
        }
    }


}

@media (min-width:1800px) {
    footer {
        nav {
            max-width: calc(100vw - 50em);

        }
    }
}