/* variables scss */
$mq-1 : 768px;
$mq-2 : 960px;
$mq-3 : 1280px;
$mq-4 : 1366px;

/* variables css */
:root {
    --color_good: #219653;
    --color_bof: #f2994a;
    --color_bad: #e6513a;
    --color_verybad: #cb2910;

    --bgk_good: #d3eadd;
    --bgk_bof: #fcebdb;
    --bgk_bad: #fbdddd;


    --grey-light: #eaeaeb;
    --grey-medium: #aaa;
    --grey-dark: #666;
    --grey-verydark: #282b2e;

    --orange: #e6513a;
    --nightblue: #28313a;


    --bluedark: #08121D;
    --bluemed: #0e1e2d;
}

/* systeme de couleurs thématiques  pour les graphiques*/
.colors_req {
    --bg1: #0d4575;
    --bg2: #1e88e5;
}

.colors_weight {
    --bg1: #4e4b4b;
    --bg2: #969292;
}

.colors_dom {
    --bg1: #cf451e;
    --bg2: #f49674;
    --bg3: #f4b299;
    --bg4: #f4cebe;

    --type-html: #f65555;
    --type-meta: #f5a83d;
    --type-scripts: #f39d26;
    --type-webcomponent: #eaa74b;
    --type-body: #f56666;
    --type-content-section: #f5e53d;
    --type-content-block: #c0f425;
    --type-content-inline: #6bf53d;
    --type-edition: #61da39;
    --type-media: #3de6f5;
    --type-multimedia: #33cad7;
    --type-svgmath: #328bca;
    --type-table: #55b3f6;
    --type-formulaire: #c775f0;
    --type-interaction: #ed5ebe;
    --deprecated: #f95c5c;
}

.colors_couverture {
    --bg1: #1e2e24;
    --bg2: #5b6d64;
}

.colors_poids {
    --bg1: #1e2e24;
    --bg2: #4a564e;
    --bg3: #757d78;
    --bg4: #a1a5a2;
    --bg5: #ccc;
}

.colors_fp {
    --bg1: #00b6cb;
    --bg2: #a9d5eb;
}

.colors_tp {
    --bg1: #ff9000;
    --bg2: #ffc780;
}

.colors_fpvstp {
    --bg1: #00b6cb;
    --bg2: #ffc780;
}

.colors_fpvstp2 {
    --bg1: #00b6cb;
    --bg2: #ff9000;
}

.colors_fpvstp_donuts {
    --bg2: #00b6cb;
    --bg1: #ff9000;
}

.colors_exectime {
    --bg1: #f44336;
    --bg2: #ffb199;
    --bg3: #dedbde;
}

/*processing*/
.colors_loading {
    --bg1: #70a2e3;
}

.colors_layout {
    --bg3: #b49bf8;
    --bg2: #9a7ee6;
    --bg1: #8670c3;
}

.colors_dns {
    --bg3: #dd66e8;
    --bg2: #ffa24c;
    --bg1: #4ca2a8;
}

.colors_css {
    --bg2: #b49bf8;
    --bg1: #8670c3;
}

.colors_paint {
    --bg1: #71b363;
}

.colors_other {
    --bg1: #e7cf89
}

/* couleurs des catégories 1p3p */
.colors_1p {
    --bg1: #00b6cb;
}

.colors_cmp {
    --bg1: #ffd400;
}

.colors_tagmanager {
    --bg1: #fff200;
}

.colors_stats {
    --bg1: #f2ff00;
}

.colors_pub {
    --bg1: #f66d00;
}

.colors_social {
    --bg1: #e694b1;
}

.colors_marketing {
    --bg1: #eda8ed;
}

.colors_hebergement {
    --bg1: #606367;
}

.colors_contenu {
    --bg1: #d75fad;
}

.colors_video {
    --bg1: #b76d9c;
}

.colors_cdn {
    --bg1: #a7a7a7;
}

.colors_utils {
    --bg1: #616161;
}

.colors_avis {
    --bg1: #cccccc;
}

.colors_autres {
    --bg1: #8c8c8c;
}

/* seo */
.colors_words {
    --bg1: #48227a;
    --bg2: #8a65b9;
    --bg3: #b18be1;
    --bg4: #d2cdd8;
}

.colors_links {
    --bg1: #2b3f8f;
    --bg2: #5b78e9;
    --bg3: #8fa6ff;
    --bg4: #d2cdd8;
}

.colors_good {
    --bg1: var(--color_good);
    --bg2: #d2cdd8;
}

.colors_bof {
    --bg1: var(--color_bof);
    --bg2: #d2cdd8;
}

.colors_bad {
    --bg1: var(--color_bad);
    --bg2: #d2cdd8;
}

.colors_headerorder {
    --bg10: #9e0142;
    --bg9: #d53e4f;
    --bg8: #f46d43;
    --bg7: #fdae61;
    --bg6: #fee08b;
    --bg5: #e6f598;
    --bg4: #abdda4;
    --bg3: #66c2a5;
    --bg2: #3288bd;
    --bg1: #5e4fa2;
    --bg0: #cccccc;
}



/* couleurs des tags (ou ltag) */

.tag_1p3p {
    --tagcolor: var(--bg1);
}

.ltag.colors_cmp,
.ltag.colors_tagmanager,
.ltag.colors_stats {
    color: #555;
}

.tag_words {
    --tagcolor: #48227a;
    color: #fff;
}

.tag_fp {
    --tagcolor: #00b6cb;
}

.tag_info {
    --tagcolor: #4e88b1;
}

.tag_pre_bad,
.tag_tls_mid,
.tag_warning,
.tag_jpg,
.tag_jpeg,
.tag_png,
.tag_gif,
.tag_woff,
.tag_tp {
    --tagcolor: #ff9000;
    color: #555;
}

.tag_tls_down,
.tag_bad,
.tag_http1,
.tag_false,
.tag_im,
.tag_svg,
.tag_otf,
.tag_eot,
.tag_ttf,
.tag_nc,
.tag_rb {
    --tagcolor: #db1111;

}

.tag_very_bad {
    --tagcolor: #000;

}

.tag_ri {
    --tagcolor: #ffd579;
    color: #666;
}

.tag_good,
.tag_pre_good,
.tag_preconnect,
.tag_tls_up,
.tag_http3,
.tag_svg,
.tag_avif,
.tag_woff2,
.tag_br {
    --tagcolor: #0e7200;
}

.tag_prefetch,
.tag_http2,
.tag_ico,
.tag_webp,
.tag_nc_1ko,
.tag_gz {
    --tagcolor: #53944a;
}

.tag_usedcode {
    --tagcolor: var(--used_code);
}

.tag_unusedcode {
    --tagcolor: var(--unused_code);
}



.tag_tbt {
    --tagcolor: #f44336;
}

.tag_cputime {
    --tagcolor: #ffccbc;
}

.tag_complexcss {
    --tagcolor: #8670c3;
}

.tag_rulescss {
    --tagcolor: #b49bf8;
}

.tag_ir {
    --tagcolor: #0d4575;
}